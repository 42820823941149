import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import { toast } from "react-toastify";
import FullReportPDF from "./FullReportPDF"; 
import moment from "moment";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import ChatBox from "./ChatBox";
import { Doughnut } from "react-chartjs-2";
import titleInsuranceData from "../utils/title_insurance_data.json";
import { PDFDownloadLink } from "@react-pdf/renderer";

const RequestChangeModal = (props) => {
  const { showModal, hideModal, offer, currentSeller } = props;
  const [activeKey, setActiveKey] = useState(null); 
  const [messages, setMessages] = useState({});
  const [newMessage, setNewMessage] = useState("");
  const [chatPosition, setChatPosition] = useState({ top: 0, left: 0 });

  const includedKeys = [
    { key: "offerType", label: "Offer Type" },
    { key: "bac", label: "Buyers Agent Compensation" },
    { key: "purchasePrice", label: "Purchase Price" },
    { key: "financingType", label: "Financing Type" },
    { key: "downPayment", label: "Down Payment" },
    { key: "financeAmount", label: "Finance Amount" },
    { key: "sellerConcessions", label: "Seller Concession" },
    { key: "earnestMoney", label: "Earnest Money" },
    { key: "residentialServiceContract", label: "Service Contract" },
    { key: "optionFee", label: "Option Fee" },
    { key: "optionDays", label: "Option Days" },
    { key: "titlePolicyExpense", label: "Title Policy Expense" },
    { key: "possession", label: "Possession" },
    { key: "leasebackDays", label: "Leaseback Days" },
    { key: "nonRealtyItem", label: "Non Realty Item" },
    { key: "rightToTerminate", label: "Right To Terminate Due to Lender’s Appraisal" },
    { key: "agentName", label: "Agent Name" },
    { key: "licenseNo", label: "Agent License No" },
    { key: "saleOfOtherProperty", label: "Sale of Other Property" },
    { key: "brokerageName", label: "Brokerage Name" },
    { key: "documents", label: "Documents" },
    { key: "buyerName", label: "Buyer Name" },
    { key: "buyerPhoneNumber", label: "Buyer Phone Number" },
    { key: "closingDate", label: "Closing Date" },
    { key: "numberOfDays", label: "Number of Days" },             
    { key: "offer_expire_date", label: "Offer Expire Date" }
  ];

  const renderValue = (key, value) => {
    if (value === null || value === undefined) return "N/A";
  
    if (key === "purchasePrice" || key === "earnestMoney" || key === "downPayment" || key === "financeAmount" || key === "sellerConcessions" || key === "residentialServiceContract" || key === "optionFee") {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
    }
  
    if (key === "documents" && Array.isArray(value)) {
      return (
        <ul style={{ listStyleType: "disc", paddingLeft: "20px", fontSize: "14px" }}>
          {value.map((doc, index) => (
            <li key={index}>
              <a href={doc.path} target="_blank" rel="noopener noreferrer">
                <strong>{doc.name}</strong> - 
                <span style={{ fontSize: 12 }}>
                  {new Date(doc.timestamp).toLocaleDateString()}
                </span>
              </a>  
            </li>
          ))}
        </ul>
      );
    }
  
    if (Array.isArray(value)) {
      return value
        .map((item) =>
          typeof item === "object"
            ? Object.entries(item)
                .map(([k, v]) => `${k}: ${v}`)
                .join(", ")
            : item
        )
        .join(" | ");
    }
  
    if (typeof value === "object") {
      return JSON.stringify(value);
    }
  
    if (key === "bac" && offer?.bac_type === "Percentage") {
      return value.includes("%") ? value : `${value}%`;
    }
  
    return value.toString();
  };

  const handleQuestionClick = (key, event) => {
    setActiveKey(key);
    const rect = event.target.getBoundingClientRect();
    setChatPosition({ top: rect.top + window.scrollY + 30, left: rect.left + window.scrollX });
  };

  console.log(activeKey, "activeKey");
  console.log(chatPosition, "chatPosition");

  const handleSendMessage = () => {
    if (!newMessage.trim()) return;

    setMessages(prevMessages => {
      const updatedMessages = { ...prevMessages };
      if (!updatedMessages[activeKey]) {
        updatedMessages[activeKey] = [];
      }
      updatedMessages[activeKey].push(newMessage);
      return updatedMessages;
    });
    setNewMessage("");
  };

  const renderMessages = (key) => {
    return messages[key]?.map((msg, index) => (
      <div key={index} style={{ padding: "5px 10px", backgroundColor: "#f1f1f1", marginBottom: "5px", borderRadius: "10px" }}>
        <span>{msg}</span>
      </div>
    ));
  };
  
  return (
    <>
      <Modal
        className="seller-net-modal-body"
        scrollable={true}
        isOpen={showModal}
        toggle={() => {
          setActiveKey(null);
          hideModal();
        }}
        size="lg"
      >
        <ModalHeader toggle={() => {
          setActiveKey(null);
          hideModal();
        }}>Request Change</ModalHeader>
        <ModalBody>
          <Row>
            <Col className="p-0">
            <p className="seller-net-sheet-description">
              Review the buyer’s offer and suggest updates to negotiate terms that work for you.
            </p>
              {offer && (
                <div className="req-change-container">
                  {includedKeys.map((item, index) => {
                    const { key, label } = item;
                    const isLastItem = index === includedKeys.length - 1;
                    if (offer.hasOwnProperty(key)) {
                      return (
                        <div
                          key={key}
                          className="d-flex justify-content-between align-items-center"
                          style={{
                            borderBottom: isLastItem ? "none" : "2px solid #fff",
                          }}
                        >
                          <span>
                            <strong>{label}:</strong> {renderValue(key, offer[key])}
                          </span>
                          <div className="relative">
                            <img
                              src={require("../assets/icons/question.png")}
                              alt="question icon"
                              className="question-icon"
                              onClick={(event) => handleQuestionClick(key, event)}
                            />
                            {activeKey === key && (
                              <ChatBox
                                activeKey={activeKey}
                                messages={messages}
                                setMessages={setMessages}
                                setActiveKey={setActiveKey}
                                chatPosition={chatPosition}
                                allKeys={includedKeys}
                              />
                            )}
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="seller-net-footer">
          <Button 
            color="secondary" 
            onClick={() => {
              setActiveKey(null);
              hideModal();
            }}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RequestChangeModal;